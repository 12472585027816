export default class TransactionService {
  constructor({ state }) {
    this.state = state
  }

  addTransaction() {

  }

  removeTransaction() {

  }

  updateTransaction() {

  }

  getBalance({ walletId, symbol }) {
    // get lastest transaction for the given symbol
  }

  getTransactions(accountId, walletId) {

  }
}
